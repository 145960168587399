.App {
  background-color: white;
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  /* min-height: 10vh; */
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  padding: 2em 1.9em 2em 2.9em;
}

.Arbo-header {
  /* min-height: 10vh; */
  height: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  padding: 1em 1.4em 1em 1.4em;
}

.spacer {
  flex: 1;
}

a {
  color: #999999;
  cursor: pointer;
}

a:hover {
  color: #222222;
}

.selected {
  color: #000000;
}

.not-selected {
  color: #999;
}

.main-nav-arb ul {
  margin: 1em 0 0;
  padding-inline-start: 1em;
  text-align: center;
}

.main-nav ul {
  margin: 2em 0 0;
  text-align: center;
}

.main-nav-arb li,
.main-nav li {
  display: inline;
}

.main-nav a {
  display: inline-block;
  padding: 0.5em 1.5em;
  text-decoration: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1em;
}

.main-nav-arb a {
  display: inline-block;
  padding: 0.5em 0.8em;
  text-decoration: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 1em;
}

footer {
  color: #999999;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 0.8em;
  padding-top: 1em;
  padding-bottom: 2em;
}

div.menu-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 65px;
  height: 50px;
  margin-top: 25px;
}

div.burger::before,
div.burger::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 25px;
  border-radius: 1px;
  background: #000;
  margin-left: -15px;
}

div.burger::before {
  transform: translateY(-5px);
}

div.burger::after {
  transform: translateY(5px);
}

ul.menu {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  position: absolute;
  width: 100px;
  background-color: #fcfcfc;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transform: translateX(-50px);
  z-index: 1;
  display: table;
}

li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #eee;
  color: #0069ca;
}

@media only screen and (min-width: 850px) {
  div.menu-button {
    display: none;
  }

  ul.main-nav-arb-list,
  ul.main-nav-list {
    display: block;
  }

  ul.menu {
    display: none;
  }
}

@media only screen and (max-width: 849px) {
  div.menu-button {
    display: flex;
  }

  ul.main-nav-arb-list,
  ul.main-nav-list {
    display: none;
  }

  ul.menu {
    display: table;
  }
}
